<template>
  <el-dialog
    title="添加目标"
    :append-to-body='true'
    :visible.sync="visibles"
    :close-on-click-modal="false" 
    width="40%">
    <el-form ref="addForm" :inline="true" :model="memberData"  class="form-item-2">
      <el-form-item label="手机号/用户名" prop="phoneNumber">
        <el-input v-model="memberData.phoneNumber" size="small"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="doSearchUser">查询</el-button>
        <el-button size="small" v-if='$isShowOrg' @click="handleSelectPcUser">选择平台用户</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-if="isShowFlags"
      :data="searchResults"
      style="width: 100%"
      row-key="id"
      size="small"
      align="center"
      v-loading="loading"
      border>
      <el-table-column
        align="center"
        label="组织/部门/姓名">
        <template slot-scope="scope">
          {{scope.row.label}}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            v-if="!scope.row.selected"
            size="mini"
            @click="handleEdit(scope.row)">添加</el-button>
          <el-button
            v-if="scope.row.selected"
            size="mini"
            type="danger"
            @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-else>
      <el-tree
        :data="treeData"
        show-checkbox
        :load="loadNode"
        lazy
        node-key="id"
        ref="tree"
        class="filter-tree"
        :check-strictly="true"
        highlight-current
        :props="defaultProps"
        empty-text="暂无数据"
        @check="treeCheckEvent">
        <span slot-scope="{ node, data }">
            <i class="el-icon-office-building" style="margin-right: 3px;" v-if="data.organizationId == undefined && data.icon == undefined"></i>
            <i class="el-icon-s-home" style="margin-right: 3px;" v-else-if="data.organizationId"></i>
            <i :class="data.icon" style="margin-right: 3px;" v-else-if="data.icon"></i>
            <span>{{data.name||data.username}}</span>
        </span>
      </el-tree>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" size="small" @click="doPopClose">确 定</el-button>
      <!-- <el-button @click="doPopClose">取 消</el-button> -->
    </span>
  </el-dialog>
</template>

<script>
import { userAPI, orgAPI } from 'liankong-ui-api'
export default {
  props: {
      //已选用户列表，进行状态过滤
      users: {
        type: Object,
        default: {}
      }
  },
  components: {},
  data () {
    return {
      visibles: false,
      memberData:{
        phoneNumber:''
      },
      searchResults:[],
      loading:false,
      isShowFlags: true, // 展示表格还是组织树
      treeData: [], // 组织树
      defaultProps: {
        children: 'groupModelList',
        label: 'name',
        isLeaf: false
      },
      alreadyCheckData: []
    }
  },
  computed: {
    targets(){
      return this.$store.state.cart.targets
    },
  },
  watch: {

  },
  methods: {
    //添加用户
    handleEdit(row){
      row.selected = true
      this.$emit('selected',row) 
    },
    // 删除用户
    handleDelete(row){
      row.selected = false
      this.$emit('removed',row) 
    },
    // 查询用户
    doSearchUser(){
      this.isShowFlags = true;
      let { phoneNumber } = this.memberData

      if(phoneNumber!==''){
        this.loading = true
        // let searchData =[]
        // userAPI.list({pageSize: 30, name: phoneNumber, phoneNumber:phoneNumber})
        //       .then(({code, data, message}) => {
        //         this.loading = false
        //         if(code == 0){

        //           //如果搜索结果为0，并且是手机号搜索
        //           if(data.records.length == 0 && /^1\d{10}$/.test(this.phoneNumber)){
        //             searchData.push({
        //               label: this.phoneNumber,
        //               value: this.phoneNumber,
        //               faceUrl: null,
        //               type: "TARGET_PHONE",
        //               selected: false
        //             })
        //           }

        //           data.records.map(user => {
        //             searchData.push({
        //               label: user.name,
        //               value: user.id,
        //               faceUrl: user.faceUrl,
        //               type: "TARGET_ID",
        //               selected: this.users[user.id] ? true : false
        //             })
        //           })

                  
        //           //搜索不到结果处理
        //           if(searchData.length == 0){
        //             this.$message.warning("未搜索到用户")
        //           }else{
        //             // this.$refs['searchResult'].open()
        //             // this.$msg.hide()
        //           }
        //         }else{
        //           // this.$msg.hide()
        //           // this.$msg.toastError(message)
        //         }
        //         this.searchResults = searchData
        //       })
        this.$store.dispatch('auth/searchTarget', {
          name: phoneNumber, 
          phoneNumber: phoneNumber
        }).then(searchData => {
            this.searchResults = searchData.map(item=>{
              item.selected=this.users[item.value] ? true : false
              return item
            });

            this.loading = false
        })
      } else {
        this.$message.warning('请输入搜索关键字')
        this.searchResults = []
      }
    },
    // 关闭弹框
    doPopClose(){
      this.searchResults = []
      this.userSearchKey = null
      this.visibles = false
    },
    // 选择平台用户
    handleSelectPcUser(){
      this.isShowFlags = false;
      this.searchResults = [];
      this.getOrgTreeList();
    },
    // 获取组织树
    async getOrgTreeList() {
        let res = await this.$store.dispatch('newOrg/getUserOrgList')
        if(res.code == 0) {
            res.data.map(item => {
              if(item.children.length != 0){
                item.children.pop()
              }
            })
            this.treeData = res.data;
            this.onLoadGroupDoc();
        } else {
            this.$message.error('获取组织数据失败')
        }
    },
    // 获取群组列表
    async onLoadGroupDoc(){
      let res = await this.$store.dispatch('newGroup/queryChildren', {
          vkey: 'allGroup',
          reset: true,
          query:{
              groupType: 1,
              pageNum: 1,
              pageSize: 1000
          } 
      })
      if(res) {
        let data =  this.$store.state.newGroup.views['allGroup'].list;
          this.treeData = this.treeData.concat(data);
      }
    },
    // 根据组织查找部门
    loadNode(node, resolve){
      if(node.data.children && !node.data.organizationId){
        resolve(node.data.children)
      }else{
        if(node.data.id  && !node.data.username){
          // 获取部门或群组成员
          orgAPI.toolDepMemberList({
            id: node.data.id,
            pageSize: 999999,
            pageNum: 1
          }).then(res=>{
            if(res.code == 0) {
                let datas = res.data;
                datas.forEach(treeChilds => {
                  this.$set(treeChilds, 'icon', 'el-icon-s-custom')
                })
                resolve(datas);
                // 隐藏成员那一级的三角形
                    this.$nextTick(function() {
                      setTimeout(() => {
                        const childNodeArr = document.getElementsByClassName('el-icon-s-custom')
                        for (let i = 0; i < childNodeArr.length; i++) {
                           const loadNode = childNodeArr[i].parentNode.parentNode.firstChild;
                          if(loadNode.className == 'el-tree-node__expand-icon el-icon-caret-right'){
                              loadNode.style.display = 'none';
                          }
                          const checkLabel = loadNode.nextElementSibling;
                          const customNode = childNodeArr[i].parentNode;
                          if(customNode.parentNode.parentNode.firstChild.className == 'el-tree-node__content'){
                            customNode.parentNode.parentNode.setAttribute('class','el-tree-node');
                            if(customNode){
                              checkLabel.style.marginLeft = "20px";
                            }
                          }
                          
                        }
                      }, 10)
                    })
            } else {
                this.$message.error(res.message);
            }
          })
        }else{
          resolve([])
        }
        
      }
      
    },
    // 左侧树选中
    treeCheckEvent(checkedNodes,checkedKeys,halfCheckedNodes,halfCheckedKeys){
        this.alreadyCheckData = checkedKeys.checkedNodes;
        for(var i = 0; i < this.alreadyCheckData.length; i++) {
          if(this.alreadyCheckData[i].icon) { // 匹配用户、手机号授权
              this.$store.dispatch('auth/searchTarget', {
                name: this.alreadyCheckData[i].name, 
                phoneNumber: this.alreadyCheckData[i].name
              }).then(searchData => {
                searchData.forEach(items =>{
                  this.$set(items, 'selected', false)
                })
                this.searchResults.push(searchData[0])
              })
          } else if(this.alreadyCheckData[i].organizationId==undefined){ // 组织
           let targets = {
              type:'TARGET_ORG',
              faceUrl: null,
              selected: false,
              value: this.alreadyCheckData[i].id,
              label: this.alreadyCheckData[i].name
            }
            this.searchResults.push(targets)
          } else if(this.alreadyCheckData[i].organizationId){ // 部门
           let targets = {
              type:'TARGET_GROUP',
              faceUrl: null,
              selected: false,
              value: this.alreadyCheckData[i].id,
              label: this.alreadyCheckData[i].name
            }
            this.searchResults.push(targets)
          }  
          // else {
          //   let targets = {
          //     type:'TARGET_ORG',
          //     faceUrl: null,
          //     selected: false,
          //     value: this.alreadyCheckData[i].id,
          //     label: this.alreadyCheckData[i].name
          //   }
          //   this.searchResults.push(targets)
          // }
        }
        this.isShowFlags = true;
    },
  },
  created () {

  },
  mounted () {
  },
}
</script>
<style lang='less' scoped>

</style>