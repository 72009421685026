<template>
    <div>
        <el-dialog
            title="权限修改"
            width="1000px"
            :close-on-click-modal="false" 
            @close='closeDialog'
            :visible.sync="dialogVisible">
            <div>
                <div class="share-top-wrap">
                    <dl class="share-head-box">
                        <dt>
                            <img src="http://app.xunjiepdf.com/ContentMain/home/pdf2word.png" alt="">
                        </dt>
                        <dd>
                            <h3>
                                <el-input v-if='showResetName' class="showResetName" size='small' v-model="shareInfo.name"  placeholder="请输入内容" @blur="onShareRename"></el-input>
                                <span v-else>{{shareInfo.name}}</span>
                                <i class="el-icon-edit-outline" @click="showResetName = true"></i>
                            </h3>
                            <div class="share-text">
                                <p>
                                    <span style="color:#afafaf">共享时间：</span>
                                    <span>{{shareInfo.createDate|date}}</span>
                                </p>
                                <p>
                                    <span style="color:#afafaf">共享人：</span>
                                    <span>{{shareInfo.ownerName}}</span>
                                </p>
                            </div>
                        </dd>
                    </dl>
                </div>
                <el-tabs v-model="shareActiveName" class="share-msg">
                    <el-tab-pane label="文件资源" name="first">
                        <div class="share-file-box">
                                <p>
                                    <template>
                                        <div>
                                            <span v-if="viewer.depth > 0" @click="back">返回上一级</span>
                                            <span @click="addShareFile" v-if="viewer.depth==0"><i class="el-icon-circle-plus"></i>添加</span> 
                                        </div>
                                    </template>
                                </p>
                                <ul>
                                    <template v-if="viewer.showFiles == undefined ? false : viewer.showFiles.length > 0">
                                        <li v-for="(item,index) in viewer.showFiles" :key="index" @click="render(item)">
                                            <p>  <el-image :src="item | thumbIcon"></el-image>
                                            <span>{{item.name}}</span></p>
                                            <i class="el-icon-delete-solid"  v-if="viewer.depth==0" @click.stop="delect(item)"></i>
                                        </li>
                                    </template>
                                    <li style="border:none;" v-else>
                                        <span style="width: 100%">暂无数据</span>
                                    </li>
                                </ul>
                            </div>
                    </el-tab-pane>
                    <el-tab-pane label="共享目标" name="second">
                        <div class="share-bottom-box">
                    <div class="share-bottom-left">
                        <p>
                            <span></span>
                            <span @click="addPeople"><i class="el-icon-circle-plus"></i>添加</span> 
                        </p>
                        <ul>
                            <li v-for="(datas,ind) in shareInfo.targets" :key="ind">
                                <span>{{datas.attrs.name || datas.value}}</span>
                                <i class="el-icon-delete-solid" @click="onTargetRemoved(datas)"></i>
                            </li>
                        </ul>
                    </div>

                </div>
                    </el-tab-pane>
                    <el-tab-pane label="操作权限" name="third">
                        <div class="share-bottom-right">
                            <el-form ref="form" label-width="100px">
                                <el-form-item label="可保存: " >
                                    <el-switch   @change="onChange($event,'archivableFlag')" :value="archivableFlag"  :active-value="1" :inactive-value="0"></el-switch>
                                </el-form-item>
                                <el-form-item label="保存文件: " v-show="archivableFlag == 1">
                                    <el-radio  :value="permissions.archivable" :label="1"  @change="onChange(1,'archivable')" >源文件</el-radio>
                                    <el-radio  :value="permissions.archivable" :label="2"  @change="onChange(2,'archivable')" >OFD版式文件</el-radio>
                                </el-form-item>
                                <el-form-item label="生效日期: " v-if="archivableFlag == 0">
                                    <el-date-picker
                                        v-model="query.expireStartTime"
                                        type="datetime"
                                        size="small"
                                        :picker-options="expireTimeOption"
                                        @change="onChange('','',true)"
                                        value-format='yyyy-MM-dd HH:mm:ss'
                                        placeholder="选择日期时间">
                                    </el-date-picker> - 
                                    <el-date-picker
                                        v-model="query.expireEndTime"
                                        type="datetime"
                                        size="small"
                                        :picker-options="expireTimeOption"
                                        @change="onChange('','',true)"
                                        value-format='yyyy-MM-dd HH:mm:ss'
                                        placeholder="选择日期时间">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item label="可共享: " v-if="archivableFlag == 0">
                                    <el-switch  @change="onChange($event,'share')" :value="permissions.share" :active-value="1" :inactive-value="0" :disabled="shareInfo.state==1||shareInfo.state==2"></el-switch>
                                </el-form-item>
                                <el-form-item label="不可复制: " v-if="archivableFlag == 0">
                                    <el-switch @change="onChange($event,'copy')" :value="permissions.copy" :active-value="1" :inactive-value="0" :disabled="shareInfo.state==1||shareInfo.state==2 || permissions.shadeAreaId !=0"></el-switch>
                                </el-form-item>
                                <el-form-item label="可打印: " v-if="archivableFlag == 0">
                                    <el-switch @change="onChange($event,'print')" :value="permissions.print" :active-value="1" :inactive-value="0" :disabled="shareInfo.state==1||shareInfo.state==2"></el-switch>
                                </el-form-item>
                                <el-form-item label="可打印次数" v-show="permissions.print == 1 && archivableFlag == 0">
                                    <el-input-number v-model="permissions.printCount" :min="0"></el-input-number> <span> <el-button size="small" @click="onChange(permissions.printCount,'printCount')">确 定</el-button> </span>
                                </el-form-item>
                                <!-- <el-form-item label="可下载: ">
                                    <el-switch @change="onChange($event,'download')" v-model="permissions.download" :active-value="1" :inactive-value="0" :disabled="shareInfo.state==1||shareInfo.state==2"></el-switch>
                                </el-form-item> -->
                                <el-form-item label="阅后即焚: " v-if="archivableFlag == 0">
                                    <el-switch @change="onChange($event,'havaReadFire')" :value="permissions.havaReadFire" :active-value="1" :inactive-value="0" :disabled="shareInfo.state==1||shareInfo.state==2"></el-switch>
                                </el-form-item>
                                <el-form-item label="隐写溯源" v-if="archivableFlag == 0">
                                    <el-switch  @change="onChange($event,'hideWrite')"  :active-value="1" :inactive-value="0"  :value="permissions.hideWrite" :disabled='configValue == "0"'></el-switch>
                                </el-form-item>
                                <el-form-item label="动态水印: " v-if="archivableFlag == 0">
                                    <el-switch @change="onChange($event,'isDynamic')" :value="permissions.isDynamic" :active-value="1" :inactive-value="0" :disabled="shareInfo.state==1||shareInfo.state==2"></el-switch>
                                </el-form-item>
                                <el-form-item label="设置水印: " v-if="archivableFlag == 0">
                                    <el-button @click="doSetWather" v-show="JSON.stringify(changWatherMsg)=='{}'"  >设置水印</el-button>
                                    <p style="margin: 0;" v-show="JSON.stringify(changWatherMsg)!='{}'"><el-tag
                                        closable
                                        @close="restChangeWather" >
                                        水印名称:{{`${changWatherMsg.name}`.length>5?`${changWatherMsg.name}`.substr(0,5)+'...':changWatherMsg.name}} 水印内容: {{changWatherMsg.text}}
                                    </el-tag></p>
                                    <!-- <el-switch @change="onChange($event,'water')" v-model="permissions.water" :active-value="1" :inactive-value="0" :disabled="shareInfo.state==1||shareInfo.state==2"></el-switch> -->
                                </el-form-item >
                                <el-form-item label="数据脱敏" v-if="showRead && archivableFlag == 0" >
                                    <el-button @click="doSemantics">语义遮掩<i class="el-icon-star-on" v-if='shareInfo.envelops.length != 0'></i></el-button>
                                    <el-button @click="doKeyword">关键字遮掩<i class="el-icon-star-on" v-if='permissions.shadeKeyword'></i></el-button>
                                    <el-button @click="doSetEnvelop">画区域遮掩<i class="el-icon-star-on" v-if='permissions.shadeAreaId != 0'></i></el-button>
                                    <SetEnvelop  ref="setEnvelop" :docInfo='docInfo' @doPermissionSet='doPermissionSet' ></SetEnvelop>
                                    <SetSemantics ref='SetSemantics' :docInfo='docInfo'  vkey='edit'   @doPermissionSet='commitEnvelops'></SetSemantics>
                                    <SetKeyword ref='SetKeyword' :docInfo='docInfo'  @doPermissionSet='doPermissionSet'></SetKeyword>
                                    <!-- <setEnvelop  ref="setEnvelop" vkey='edit'  :docInfo='docInfo' @handleCheckedChange='commitEnvelops' @doPermissionSet='doPermissionSet'></setEnvelop> -->
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="share-read-box" v-if="showRead">
                    <p v-if="archivableFlag == 0">
                        <span>阅读范围</span>
                    </p>
                    <el-collapse v-model="activeNames" @change="handleChange" accordion v-if="archivableFlag == 0">
                        <el-collapse-item name="1">
                            <template slot="title">
                                <el-row type="flex" justify="space-between" class="document-title" @click.native.stop="handleShow">
                                    <el-col :span="17">
                                        {{this.shareInfo.resources.length == 1&& this.shareInfo.resources[0].type!=0 ? this.shareInfo.resources[0].name : ''}}
                                    </el-col>
                                    <el-col :span="8" class="el-readios">
                                        <div class="document-title-right">
                                            <el-radio-group
                                                v-model="radioSelect"
                                                class="document-title-right-radiu"
                                                @change="radioChangeEvent">
                                                <el-radio :label="1">全部页面</el-radio>
                                                <el-radio :label="2">部分页面</el-radio>
                                            </el-radio-group>
                                        </div>
                                    </el-col>
                                    <el-col :span="3">
                                        <el-button type="primary" size="small" plain @click="editCommit" v-if="shareInfo.state!==1&&shareInfo.state!==2">修改提交</el-button>
                                    </el-col>
                                </el-row>
                            </template>
                            <div class="container">
                                <el-card :class="{'document-select document':item.selected }"
                                    v-for="(item,i) in showReadList"
                                    :key="i"
                                    :body-style="{ padding: '0px' }"
                                    @click.native="onPageSelectEvent(item)">
                                    <div class="document-top">
                                        <img :src="item.path|thumbnailUrl"
                                        :alt="item.path | thumbnailUrl" />
                                    </div>
                                    <div class="document-middle">{{ item.pageIndex + 1 + '页'}}</div>
                                    <img src="@/assets/select.png" alt="" class="select" v-if="item.selected"/>
                                </el-card>
                            </div>
                            <div class="pagination">
                                <el-pagination
                                    background
                                    @current-change="pageChanged"
                                    layout="prev, pager, next"
                                    :page-size="pages.pageSize"
                                    :total="pages.total">
                                </el-pagination>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" size="small" @click="dialogVisible = false">确 定</el-button> -->
            </span>
        </el-dialog>
        <ShareWatemarkDialog ref="shareWatemark" @watherInfo="onWatemarkCreated"/> 
        <AddShareFile :shareInfo="shareInfo" @addFile='addFile' ref="addShareFile" />
        <AddSharePeople ref="addSharePeople" :users='targets' @selected="onTargetAdd" @removed="onTargetRemoved" />
    </div>
</template>
<script>
import { requestAPI, shareAPI, qrReadAPI, documentAPI} from 'liankong-ui-api' 
import AddShareFile from "@/views/share/AddShareFile.vue"
import ShareWatemarkDialog from '@/packages/FileCart/src/ShareWatemarkDialog.vue'
import AddSharePeople from '@/views/share/AddSharePeople.vue'
 import SetEnvelop from '@/packages/FileCart/src/SetEnvelop.vue'
 import SetSemantics from '@/packages/FileCart/src/SetSemantic.vue'
 import SetKeyword from '@/packages/FileCart/src/SetKeywords.vue'
export default {
    data() {
        return {
            shareActiveName:'first',
            dialogVisible: false,
            shareRowInfos: {},
            expireTimeOption: {
                disabledDate(date) {
                    return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
                }
            },
            activeNames:'1', 
            page: '', //
            permissions:{}, // 权限
            showRead:false,
            shareInfo:{},
            docList:[], // 
            radioSelect: '',  // 全部页面和部分页面
            selectCount: 0, // 选择的个数
            verker:'shareDocList',
            pages:{
                pageSize:20,
                total:0
            },
            archivableFlag:0,//是否归档
            query:{
                expireStartTime:null,
                expireEndTime:null,
                pageNum: 1,
                pageSize: 20
            },
            showReadList:[], // 页面回显 envelopList:[],
            envelopCheck:[],
            list:[],
            shareId:'',
            isEnvelop:false,
            regionDes:false,
            iframeSrc:null,
            changWatherMsg:{}, //水印信息详情
            showResetName:false,
        };
    },
    components: {
        AddShareFile,
        AddSharePeople,
        SetEnvelop,
        SetSemantics,
        SetKeyword,
        ShareWatemarkDialog,
    },
    computed: {
        viewer() {
            return this.$store.state.doc.viewer[this.verker] || {}
        },
        targets(){
            return this.shareInfo.targets ? this.shareInfo.targets.reduce((map, target) => {
                const key = target.value
                map[key] = target
                return map
            }, {}) : {}
        },
        docInfo() {
            const resources = this.$store.state.auth.editShare.resources || []
            if(resources.length == 1){
                //如果是文件，则可以选择，就算只有一个且是文件夹，也不行
                const doc = resources[0];
                doc.id = doc.documentId;
                return doc.type === 1 ?  doc: null
            }
            return null       
        },
        configValue(){
            return this.$store.state.share.configValue
        } 
    },
    methods: {
        handleShow(){

        },
        closeDialog(){
            this.showResetName = false;
            this.activeNames = '1'; 
            this.page = ''; //
             this.permissions = {}; // 权限
             this.showRead = false;
             this.shareInfo = {};
             this.docList = [];// 
             this.radioSelect =  '';  // 全部页面和部分页面
             this.selectCount =  0;// 选择的个数
             this.verker = 'shareDocList';
             this.pages = {
                pageSize:20,
                total:0
            }
             this.query = {
                expireStartTime:null,
                expireEndTime:null,
                pageNum: 1,
                pageSize: 20
            }
             this.showReadList = []; // 页面回显 envelopList:[],
             this.envelopCheck = [];
             this.list = [];
             this.shareId = '';
             this.isEnvelop = false;
             this.regionDes = false;
             this.iframeSrc = null;
             this.changWatherMsg = {}; //水印信息详情
             this.showResetName = false;
             this.archivableFlag = 0;
        },
        restChangeWather(){
            this.changWatherMsg = {}
            this.permissions.water = 0
            this.onChange(0,'water')
        },
        doSetWather(){
            this.$refs['shareWatemark'].showMakeLoding(this.permissions.water?this.permissions.water :'')
        },
        onWatemarkCreated(val,row){
            this.permissions.water = val
            this.changWatherMsg = row[0]
            this.onChange(val,'water')
        },
        onLoadEditInit(row) {
           this.shareRowInfos = row;

           this.onload();
        },
        //重命名
        onShareRename(){
            if(this.shareInfo.name.length<2 || this.shareInfo.name.length > 30){
                this.$message.error('共享名称请设置2~30个字符！');
                return
            }
            
            this.$store.dispatch("share/rename", {
                id: this.shareInfo.id,
                name:this.shareInfo.name
            }).then(state => {
                if(state){
                    this.showResetName = false;
                    this.$message.success("共享名称更新成功!")
                }else{
                    this.$message.error("共享名称更新失败，请重试!")
                }
            }).catch(err => {
                this.$message.error("共享名称更新失败，请重试!")
            })
        },
        onload(){
            this.$store.dispatch("auth/loadShare", this.shareRowInfos.id).then(data => {
                this.shareInfo = this.$store.state.auth.editShare
                
              
                this.query.expireStartTime = this.shareInfo.expireStartTime
                this.query.expireEndTime = this.shareInfo.expireEndTime
                
                this.permissions = this.shareInfo.permissions ? this.shareInfo.permissions.reduce((map, p) => {
                    map[p.name] = p.value
                    return map
                }, {}) : null
        
                this.archivableFlag  = this.permissions.archivable == 0?0:1;
                if(this.permissions.water){
                    if(this.permissions.water!=0&&this.permissions.water!=1){
                        shareAPI.getShareWatherDetail(this.permissions.water).then(res=>{
                            if(res.code==0){
                                this.changWatherMsg = res.data
                            } else {
                                this.changWatherMsg = {}
                                this.$message.error('获取水印信息失败')
                                }
                        })
                    } else {
                        this.changWatherMsg = {}
                    }
                }
               
                if(!this.permissions.printCount){
                    this.permissions.printCount = 0
                }
                this.$store.commit('doc/createViewer', {
                    viewerKey: this.verker,
                    defaultFiles: this.shareInfo.resources
                })
                this.showDoument()
                this.shareId =this.shareInfo.resources[0].shareId;
                let documentId = this.shareInfo.resources[0].documentId;
            })
        },
        showDoument(){
            if (this.shareInfo.resources.length == 1) {
                let {resources} = this.shareInfo
                if(resources[0].type!=0){
                    let docId = resources[0].documentId || resources[0].id
                    this.documentList(docId)
                }
            } else {
                this.showRead = false
            }
        },
        documentList(docId){
            let {pageNum,pageSize} = this.query
            documentAPI.thumbnail(docId,pageSize,pageNum).then(({code, data, message}) => {
                if(code == 0){
                    this.showRead = true 
                    const { pages, records, total, current } = data
                    this.pages.total = total
                if(this.radioSelect==1 || this.permissions.readPageType==0){
                    // 选中全部页面
                    this.showReadList = records.map(page=>{
                        page.selected = true
                        return page
                    })
                    // 第一次加载
                    if(this.docList.length==0){
                        this.docList = [...this.showReadList]
                        this.radioSelect = this.selectCount == this.pages.total || this.permissions.readPageType==0 ? 1 : 2
                    } else {
                    this.selectCount = 0
                    records.forEach((items,i)=>{
                        const Index = this.docList.findIndex((item) => {return item.path == records[i].path})
                        if(Index==-1){
                            items.selected = true
                            this.docList.push(items)
                        }
                    })
                    records.forEach(item=>(
                        this.docList.forEach(ele=>{
                        if(item.path==ele.path){
                            item.selected = ele.selected
                        }
                        })
                    ))
                    this.showReadList = [...records]
                    this.docList.forEach(item=>{
                        if(item.selected){
                            this.selectCount++
                        }
                    })
                        this.radioSelect = this.selectCount == this.pages.total ? 1 : 2
                    }
                    
                } else if(this.permissions.readPageType==0){
                    // 第一次加载
                    if(this.docList.length==0){
                        this.showReadList = records.map(page=>{
                            page.selected = true
                            return page
                        })
                        this.docList = [...this.showReadList]
                    } else {
                        this.selectCount = 0
                        records.forEach((items,i)=>{
                            const Index = this.docList.findIndex((item) => {return item.path == records[i].path})
                            if(Index==-1){
                                items.selected = true
                                this.docList.push(items)
                            }
                        })
                        records.forEach(item=>(
                            this.docList.forEach(ele=>{
                                if(item.path==ele.path){
                                    item.selected = ele.selected
                                }
                            })
                        ))
                        this.showReadList = [...records]
                        this.docList.forEach(item=>{
                            if(item.selected){
                            this.selectCount++
                            }
                        })
                        this.radioSelect = this.selectCount == this.pages.total ? 1 : 2
                    }
                }
                else{
                    const readPage = this.permissions.readPage
                    //数据打标
                    const readPageList =typeof(readPage)=='number'?[readPage.toString()]:( readPage ? readPage.split(',') : null)
              
                    if(this.docList.length==0){
                        this.showReadList = records.map(page => {
                           
                            page.selected = readPageList ? readPageList.indexOf("" + page.pageIndex) > -1 : false
                            
                            return page
                        })
                        this.docList = [...this.showReadList]
                        this.radioSelect = this.selectCount == this.pages.total ? 1 : 2
                    } else {
                        
                        this.selectCount = 0
                        records.forEach((items,i)=>{
                            const Index = this.docList.findIndex((item) => {return item.path == records[i].path})
                            if(Index==-1){
                                items.selected = readPageList ? readPageList.indexOf("" + items.pageIndex) > -1 : false
                                this.docList.push(items)
                            }
                        })
                        records.forEach(item=>(
                            this.docList.forEach(ele=>{
                                if(item.path==ele.path){
                                    item.selected = ele.selected
                                }
                            })
                        ))
                        this.showReadList = records
                        this.docList.forEach(item=>{
                            if(item.selected){
                                this.selectCount++
                            }
                        })
                        this.radioSelect = this.selectCount == this.pages.total ? 1 : 2
                    }

                }
                }else{
                    this.showRead = false
                    this.$message.error(message)
                }
            })
        },
        doPermissionSet(key,value){
            this.onChange(value,key)
        },
        // 设置数据脱敏
        doSetEnvelop(){
            this.$refs['setEnvelop'].dialogVisible = true;
            if(this.permissions.shadeAreaId){
                this.$refs['setEnvelop'].shadeAreaId = this.permissions.shadeAreaId;
            }
            this.$refs['setEnvelop'].getIframeUrl();
        },
        //提交脱敏信息
        commitEnvelops(key,list){
            shareAPI.resetEnvelop(this.shareId,list).then(res=>{
                if(res.code==0){
                    this.$message.success('变更成功！')
                    this.onload()
                }else{
                    this.$message.error('变更失败！')
                }
            }).catch(message => {
                this.$message.error('变更失败！')
            })
        },
        handleChange(){
            this.activeNames = '1'
        },
        // 提交修改
        editCommit(){
            const selectPageNumList = this.docList.filter(p => p.selected).map(p => p.pageIndex)
            if(this.radioSelect == 1 || this.selectCount === 0){
                this.onChange(0,'readPageType',false,'修改')
                this.onChange(null,'readPage',false,'修改',false)
            } else {
                const selectPageNumList = this.docList.filter(p => p.selected).map(p => p.pageIndex)
                this.onChange(1,'readPageType',false,'修改')
                this.onChange(selectPageNumList.join(','),'readPage',false,'修改',false)
            }
        },
        // 是否确认
        onSureCommit($event,val,state=false,type,msg=true){
            let stateVal = {}
            let authUpdate = ''
            if (state) {
                if(val == 'archivableFlag'){}
                authUpdate = 'share/updateShareExpireTime'
                if(!this.$options.filters['dateRengeCompare'](this.query['expireStartTime'], this.query['expireEndTime'])){
                        this.query.expireStartTime=this.shareInfo.expireStartTime
                        this.query.expireEndTime=this.shareInfo.expireEndTime
                        this.$message.warning("结束时间不应在于开始时间")
                    return
                }
                this.loadingShow('变更中')
                let  {expireStartTime,expireEndTime} = this.query
                this.shareInfo.expireStartTime = expireStartTime!==null ? expireStartTime : ''
                this.shareInfo.expireEndTime = expireEndTime!==null ? expireEndTime:''
                stateVal = {
                    id:this.shareInfo.id,
                    expireStartTime: this.shareInfo.expireStartTime,
                    expireEndTime: this.shareInfo.expireEndTime,
                }
            } else {
                if($event==null){
                    authUpdate = 'auth/removeSharePermission';
                    
                }else{
                    authUpdate = 'auth/updateOrAddPermission'
                }
                if(val == 'archivableFlag'){
                    stateVal = {
                        shareId:this.shareInfo.id,
                        permission: {
                            name: 'archivable',
                            value:$event
                        }
                    }
                }else{
                    stateVal = {
                        shareId:this.shareInfo.id,
                        permission: {
                            name: val,
                            value:$event
                        }
                    }
                }
            }
            this.$store.dispatch(authUpdate,stateVal ).then(state => {
                this.loadingHide()
                if (state.code==1) {
                    this.permissions[val] = !$event
                    this.$message.error(state.message)
                    return
                }  
                else if(type=="修改") {
                    this.cancleCommit()
                }
                this.onload();
                this.$message.success('变更成功')

            }).catch(err => {
                this.loadingHide()
                this.$message.error('变更失败')
            })
        },
        // 变更状态
        onChange($event,val,state=false,type,msg=true){
            console.log($event)
            if(val == 'readPageType' || val == 'readPage'){
                this.onSureCommit($event,val,state,type,msg=true)
            }else{
                this.$confirm('确定修改此权限信息吗？', '系统提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.onSureCommit($event,val,state,type,msg=true)
                
                }).catch(err => {
                    this.onload();
                })
            }
        },
         // 取消
        cancleCommit(){
            // this.$router.replace({path:'/shares/shares'})
            this.$router.replace({path:'/sharetion/sharedoc'})
            this.dialogVisible = false;
        },
        // 添加共享目标
        addShareFile(){
            this.$refs['addShareFile'].visibles = true
        },
        addFile({state,resource}){
            if(resource == undefined) {
                return
            }
            // this.envelopList=state?[]:this.envelopList
            this.isEnvelop = resource.length==1?true:false
            state ? this.showDoument() : this.showDoument('添加')
        },
        back(){
            this.$store.dispatch('doc/backFolder', {
                vkey: this.verker
            })
        },
        // 跳转ofd页面
        render(docInfo){
            docInfo.documentId ? docInfo.documentId = docInfo.documentId : docInfo.documentId = docInfo.id
            if(docInfo.type==0){
                this.$store.dispatch("doc/loadChildrenByShare", {
                vkey: this.verker,
                folder: docInfo
                })
                return
            }
            this.$ebus.$emit('publicReader',{
                shareId:this.shareInfo.id, 
                id:docInfo.documentId || docInfo.id,
                documentId: docInfo.documentId
            })
        },
        // 删除共享文件
        delect(val){
            let resource = val
            if(this.shareInfo.resources.length==1){
                this.$message.error('至少有一个文件哦');
            }else{
                this.$store.dispatch("auth/removeShareResource", {
                    shareId: this.shareInfo.id,
                    resource
                }).then(res=>{
                    this.addFile(true,resource)
                })
            }
        },
        addPeople(){
            this.$refs['addSharePeople'].visibles = true
        },
        //移除目标
        onTargetRemoved(target){
            this.loadingShow('回收中')
            this.$store.dispatch('auth/removeShareTarget', {
                shareId: this.shareInfo.id,
                target
            }).then(state =>{
                this.loadingHide()
                this.$message.success("授权已收回")
            }).catch(err => {
                console.log(err)
                this.$msg.toastError("收回失败!")
            })
        },
        // 添加目标
        onTargetAdd(target){
            this.loadingShow('授权中')
            this.$store.dispatch('auth/addShareTarget', {
                shareId: this.shareInfo.id,
                target
            }).then(state =>{
                this.loadingHide()
                this.$message.success("授权已完成")
            }).catch(err => {
                console.log(err)
                this.$msg.toastError("授权失败!")
            })
        },
        radioChangeEvent(value){
            if(value == 1) { // 点击全部页面
                this.selectCount = this.page.total;
                this.docList.forEach(listItem=>{
                    this.$set(listItem,'selected',true)
                })
                this.showReadList.forEach(item=>{
                    this.$set(item,'selected',true)
                })
            }
        },
        onPageSelectEvent(item){
            // item.selected = !item.selected
            this.showReadList.forEach(items=>{
                if(items.path==item.path){
                    items.selected = !items.selected
                }
            })
            // this.$set(item,'selected',!item.selected)
            this.$nextTick(() => this.$forceUpdate())
            this.docList.forEach(items=>{
                if(items.path==item.path){
                    items.selected = item.selected 
                }
            })
            if (item.selected) {
                this.selectCount++;
                this.radioSelect =  this.selectCount == this.pages.total ? 1 : 2
            } else {
                this.selectCount--;
                this.radioSelect = this.selectCount == this.pages.total ? 1 : 2
            }
        },
        pageChanged(num){
            this.query.pageNum = num
            this.showDoument()
        },
        // 语义遮掩
        doSemantics(){
            this.$refs.SetSemantics.dialogVisible = true;
            this.$refs.SetSemantics.getEnvelopField()
        },
        // 关键字遮掩
        doKeyword(){
            if(this.permissions.shadeKeyword){
                this.$refs.SetKeyword.keyWordList = this.permissions.shadeKeyword.split(',')
            }
                this.$refs.SetKeyword.dialogVisible = true;
            },
        }
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
    .el-dialog__body {
        padding: 10px 20px;
    }
}
.showResetName{
    width: 800px;
}
.share-top-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .share-head-box {
        display: flex;
        align-items: center;
        padding: 0px;
        margin: 0px;
        h3{
            display: flex;
            align-items: center;
            span{
                max-width: 800px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        i{
            cursor: pointer;
            margin-left: 10px;
        }
        dt {
            width: 38px;
            height: 38px;
            min-width: 38px;
            img {
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 2px;
            }
            margin-right: 10px;
        }
        dd {
            padding: 0px;
            margin: 0px;
            h3 {
                padding: 0px;
                margin: 0px 0px 4px 0px;
                font-size: 18px;
            }
            .share-text {
                display: flex;
                p {
                    padding: 0px;
                    margin: 0px;
                    span {
                        font-size: 13px;
                        
                       
                    }
                }
                p:first-child {
                    margin-right: 20px;
                }
            }
        }
    }
}
.share-file-box {
    
    p {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0;
        span:nth-child(2) {
            cursor: pointer;
        }
        div {
            display: flex;
            align-items: center;
            margin-top: 1px;
            cursor: pointer;
            span {
                display: flex;
                align-items: center;
            }
            i {
                color: #59b880;
                font-size: 20px;
                margin-left: 10px;
            }
        }
    }
    ul {
        margin: 0px;
        padding: 0px;
        height: 400px;
        overflow-y: auto;
        li {
            list-style: none;
            height: 35px;
            line-height: 35px;
            border-bottom: 1px solid #f2f2f2;
            font-size: 14px;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: space-between;
           
            .el-image {
                margin-right: 5px;
                width: 21px;
                height: 21px;
                img {
                    display: block;
                }
            }
            span {
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            i {
                margin-left: 20px;
                color: red;
            }
        }
        li:hover {
            color: #409eff;
        }
    }
}
.share-bottom-box {
    display: flex;
    justify-content: space-between;
    .share-bottom-left {
        width: 100%;
        // margin-right: 10px;
        ul {
            margin: 0px;
            padding: 0px;
            height: 400px;
            overflow-y: auto;
            li {
                list-style: none;
                height: 25px;
                line-height: 25px;
                // border-bottom: 1px solid #f2f2f2;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                .el-image {
                    margin-right: 5px;
                    width: 21px;
                    height: 21px;
                    img {
                        display: block;
                    }
                }
                i {
                    margin-left: 20px;
                    color: red;
                }
            }
            li:hover {
                color: #409eff;
            }
        }
    }
    p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        span:nth-child(2) {
            display: flex;
            align-items: center;
            i {
                color: #59b880;
                font-size: 20px;
            }
        }
    }
}
.share-bottom-right {
        width: 100%;
        margin-left: 10px;
        .el-form {
            .el-form-item {
                border-bottom: 1px dashed #e7eef4;
                margin-bottom: 0px;
                padding: 5px 0;
                .el-tag::before {
                    background: none;
                }
            }
        }
    }
.share-read-box {
    p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 25px 0 5px 0;
        span:nth-child(1) {
            position: relative;
            padding-left: 20px;
            font-size: 15px;
            &::before{
                content: '';
                // width: 3px;
                // height: 18px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #409eff;
                position: absolute;
                left: 0px;
                top: 4px;
            }
        }
    }
    /deep/ .el-collapse {
        border: none;
        .el-collapse-item__header {
            display: flex;
            height: 30px;
            line-height: 30px;
            border: none;
            .document-title {
                flex: 1;
            }   
        }
        .el-collapse-item__arrow{
            display: none;
        }
        .el-collapse-item__wrap {
            border: none;
            display: grid!important;
            .el-collapse-item__content {
                padding: 0px;
                .container {
                    display: grid;
                    margin-top: 15px;
                    grid-template-columns: repeat(10, 1fr);
                    grid-row-gap: 10px;
                    grid-column-gap: 10px;
                    padding: 16px;
                    background: #f0f1f4;
                }
                .document {
                    text-align: center;
                    box-sizing: border-box;
                    position: relative;
                }
                .document-select{
                    border: 1px solid #409eff;
                }
                .document img.select {
                    position: absolute;
                    right: 0;
                    bottom: 0px;
                    font-size: 20px;
                }
                .document-top {
                    height: 40px;
                    overflow: hidden;
                }
                .document-top img {
                    width: 100%;
                    height: 40px !important;
                    overflow: hidden;
                    
                }
                .document-middle{
                    text-align: center;
                    margin-top: 5px;
                }
                .pagination {
                    margin-top: 8px;
                }
            }
        }
    }
}
.share-msg {
    margin-top: 10px;
   /deep/ .el-tabs__header {
        .el-tabs__nav-wrap::after {
            width: auto;
        }
    }
    
}
</style>