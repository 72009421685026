<template>
  <el-dialog
    title="添加资源"
    :append-to-body='true'
    :visible.sync="visibles"
    :close-on-click-modal="false" 
    width="40%">
    <!-- 暂时先去掉 -->
    <!-- <el-form ref="addForm" :inline="true" :model="memberData"  class="form-item-2">
      <el-form-item label="文档标题/内容" prop="phoneNumber">
        <el-input v-model="memberData.fileName"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="doSearchUser">查询</el-button>
      </el-form-item>
    </el-form> -->
      <i title="返回上一级" v-if="viewer.depth>0" class="el-icon-back" @click="doFolderBack"></i>
    <el-table
    class="table"
      :data="viewer.showFiles"
      style="width: 100%"
      row-key="id"
      size="small"
      align="center"
      v-loading="loading"
      border>
      <el-table-column
      align="center"
        label="文件名称">
        <template slot-scope="scope">
         <span style="width:100%;height:100%;cursor:pointer;display:inline-block"  @click="forward(scope.row)"> {{scope.row.name}}</span>
        </template>
      </el-table-column>
       <el-table-column label="操作">
        <template slot="header" slot-scope="scope">
          <span>操作</span>
        
      </template>
      <template slot-scope="scope">
        <el-button
          size="mini"
          @click="handleEdit(scope.row)">添加</el-button>
        <el-button
          size="mini"
          type="danger"
          @click="handleDelete(scope.row)">取消添加</el-button>
      </template>
    </el-table-column>
    </el-table>
      <el-pagination
      background
      v-if="viewer.depth<=0"
      layout="prev, pager, next"
      :page-size='query.pageSize'
      @current-change="pageChanged"
      :total="viewer.paging.total">
    </el-pagination>
    <span slot="footer" class="dialog-footer">
      <el-button @click="doPopClose">取 消</el-button>
      <el-button type="primary" @click="addFile">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { userAPI } from 'liankong-ui-api'
export default {
  name:'AddShareFile',
  props: {
    shareInfo: {
      type: Object,
      default: {}
    }
  },

  components: {},
  data () {
    return {
      visibles: false,
      memberData:{
        fileName:''
      },
      query:{
        pageSize:10
      },
      loading:false,
      viewerKey:'addShareFile',
      fileList:[],
      verker:'shareDocList'
    }
  },
  computed: {
    viewer() {
      return this.$store.state.doc.viewer[this.viewerKey] || {};
    },
  },
  watch: {

  },
  methods: {
    // 添加文件
    addFile(){
      if(this.fileList.length==0){
        this.$message.error('请选择一个文件')
        return
      }
      //如果创建文件时公开，后续追加的文件都为公开
      let   accessTypes= null;
      if(this.shareInfo.resources[0].accessTypes == "PUBLIC"){
       accessTypes= "PUBLIC"
      }else{
        accessTypes  ="AUTH"
      }
      this.fileList[0].accessTypes= accessTypes;
      let filesIdList = this.fileList.map(item=>item.documentId)
      this.$store.dispatch('doc/batchCheckDocumentSharePermission',filesIdList).then(res=>{
            let {code,data,message} = res
                if(code==0){
                  if(data.length>0){
                    this.$confirm('当前文件存在不可共享文件,是否自动转换文件之后进行共享?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                      }).then(() => {
                        let converFile = data.map(item=>item.id)
                          this.$store.dispatch('doc/batchConvertOFD',converFile).then(ofdRes=>{

                          if(ofdRes.code==0){
                            this.addShareFile()
                          } else {
                            this.$message.error(ofdRes.message)
                          }
                          })
                      }).catch(() => {});
                  } else {
                    this.addShareFile()
                  }
                } else {
                  this.$message.error(message)
                }
            })
    },
    addShareFile(){
      this.$store.dispatch('auth/addShareResources',{
         id:this.shareInfo.id,
         resources:this.fileList
       }).then(res=>{
        this.$store.commit('doc/createViewer', {
          viewerKey: this.verker,
          defaultFiles: this.shareInfo.resources
        })
         this.visibles = false
         this.fileList = []
         this.$emit('addFile',false,this.shareInfo.resources)
       })
    },
    handleDelete(item){
      let flag = this.fileList.some(res=> res.documentId===item.id)
      if(flag){
        this.fileList = this.fileList.filter(res=>res.documentId != item.id)
      } else {
        this.$message.warning('您未选中该条数据！')
      }
    },
    handleEdit(item){
      let flag = this.fileList.some(res=> res.documentId===item.id)
      if(!flag){
        this.fileList.push({
          accessTypes: 'AUTH',
          attrs:{},
          documentId:item.id,
          type:item.type,
          name:item.name,
          ownerId:item.ownerId,
          suffix:item.suffix
        })
      } else {
        this.$message.warning('您已经选中该条数据！')
      }
    },
    // 回退文件夹
    doFolderBack(){
        this.$store.dispatch("doc/backFolder", {
          vkey: this.viewerKey,
          back:true
        })

        if(this.viewer.depth<=1){
          this.onload()
        }
    },
    // 前进文件夹
    forward(folder){
      if(folder.type==0){
        this.$store.dispatch("doc/enterFolder", {
          vkey: this.viewerKey,
          folder
        });
      }
    },
    // 查询用户
    pageChanged(num){
      this.query.pageNum = num
      this.onload()
    },
    onload(){
      let {pageNum,pageSize} = this.query
      this.$store.dispatch("doc/loadChildren", {
        vkey: this.viewerKey,
        reset: true,
        pageNum,
        pageSize
      });
    },
    // 关闭弹框
    doPopClose(){
      this.memberData.fileName = ''
      this.fileList = []
      this.visibles = false
      this.onload()
    },
  },
  created () {

  },
  mounted () {
    this.onload()
  },
}
</script>
<style lang='less' scoped>
.table {
  /deep/ .el-table__expand-icon {
    display: none;
  }
}
</style>